//
// Modal
//

// Base
.modal {
  // Mobile header
  .modal-header {
    align-items: center;
    justify-content: space-between;
    @include border-top-radius($modal-content-border-radius);

    // Headings
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  // Mobile dialog
  .modal-dialog {
    outline: none !important;
  }
}

// Utilities
.modal-rounded {
  @if $enable-rounded {
    border-radius: $modal-content-border-radius !important;
  }
}

.modal {
  .modal-title {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 700;
  }
  &-logo {
    background-color: var(--gf-light-gray-300);
    width: 70px;
    height: 70px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin: 0 auto;
  }
  &-company {
    &__verify {
      .modal-header {
        text-align: center;
        border-bottom: 0;
        position: relative;
        height: 0;
        padding-bottom: 0;
        margin-bottom: -20px;
        button {
          position: absolute;
          right: 15px;
          z-index: 10;
        }
      }
      .modal-body {
        text-align: center;
      }
    }
  }
}
